<template>
  <div>
    <v-card>
      <v-card-title class="headline">
        Usuario encontrado
        <v-btn
          @click="$emit('cancel')"
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="ma-4">
          <v-col cols="12" sm="12" md="12">
            <h3>Nombre del Cliente</h3>
            <p>
              <b>{{ user.name }} {{ user.surname }}</b>
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <h3>Celular del Cliente</h3>
            <p>
              <b>{{ user.phone }}</b>
            </p>
          </v-col>

          <v-col class="mb-0 pb-0" cols="12" sm="12" md="12">
            <h3>Email del Cliente</h3>
            <p>
              <b>{{ user.email }}</b>
            </p>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" sm="12" md="12">
            <v-row justify="end">
              <v-btn
                class="save-btn"
                color="primary"
                :loading="loading"
                @click="$emit('associate')"
                dark
                >Asociar usuario</v-btn
              >
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "users-details",
  props: ["user", "loading"],
};
</script>

<style scoped>
</style>